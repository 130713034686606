import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import { Link } from 'gatsby'
import Hero from '../../components/hero'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'


const characters = [
  {name: 'Santuzza', voice: 'Soprano', desc: 'A young, devout woman without much, in a relationship with Turiddu.'},
  {name: 'Turiddu', voice: 'Tenor', desc: 'A young man, recently returned from military service and now in a relationship with Santuzza but also sleeping with Lucia.’'},
  {name: 'Lucia', voice: 'Contralto', desc: 'Turiddu’s mother. Owns a wine shop.'},
  {name: 'Alfio', voice: 'Baritone', desc: 'Lucia’s husband.'},
  {name: 'Lola', voice: 'Mezzo-soprano', desc: 'A young woman, married to Alfio but having an affair with Turiddu.'},
];

const inbrief = {name: "Cavalleria rusticana", composer: "Pietro Mascagni", librettist: " Giovanni Targioni-Tozzetti and Guido Menasci", language: "Italian", date: "May 17th, 1890", acts: "One", musiclength: "One hour, twenty minutes"};

const bibliography = [
    {name: 'Pietro Mascagni and His Operas', writer: 'Alan Mallach', url: 'https://amzn.to/3BmY9x8'},
    {name: 'Stories of the Great Operas & their Composers', writer: 'Ernest Newman', url: 'https://amzn.to/2T3IjBt'},
    {name: 'The Autumn of Italian Opera: From Verismo to Modernism, 1890-1915', writer: 'Alan Mallach', url: 'https://amzn.to/3BAsMiL'},
    {name: 'Changing Tunes: The Use of Pre-existing Music in Film', writer: 'Phil Powrie & Robynn Stilwell', url: 'https://amzn.to/3RKQfEE'},
    {name: 'Music in Fascist Italy', writer: 'Harvey Sachs', url: 'https://amzn.to/3RP75SC'},
];

// markup
const CavalleriaPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Cavalleria rusticana | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/cavalleria" />
          <meta name="description" content="A guide to Mascagni's one-act masterpiece, Cavalleria rusticana. Including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>
        <Hero title="Cavalleria rusticana" subtitle="An Opera by Pietro Mascagni"/>
        <MainBodySection>

          <p><em>Cavalleria rusticana</em> (‘Rustic Chivalry’) is an intense, eighty-minute rollercoaster of an opera. A classic tale of romance, betrayal and revenge. A massive hit with audiences at its premiere in 1890, <em>Cavalleria</em> has been a mainstay of the repertory ever since. Despite being a decent evening in its own right, it is almost always performed as one-half of a double feature, most often with <em>Pagliacci</em> (the so-called Cav/Pag bill).</p>

          <p>As we’ll explore in-depth, <em>Cavalleria</em> is generally considered the first <i>Verismo</i> opera. This category of Italian opera disposes of the traditional operatic focus on royalty, mythology and other ‘noble’ subjects. Instead, these operas concern regular, contemporary people and their trials and tribulations. As you’ll see with <em>Cavalleria</em>, these problems are almost always sexual and violent.</p>

          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <p>For all the narrative grubbiness, though, <em>Cavalleria</em> has some beautiful music. Mascagni knew how to write a bombastic aria, as he demonstrates with Turiddu’s ‘Mamma, quel vino è generoso’. On the flip side, the narrative takes place on Easter morning, leaving ample opportunity for big religious choral numbers such as the Easter Hymn.</p>

          <p><em>Cavalleria rusticana</em> will sweep you up with its punchy, dramatic plot and magnificent music, all in under an hour and a half!</p>

          <VideoPlayer src="https://www.youtube.com/embed/n6D5ZNwqYB0?autoplay=1" id="n6D5ZNwqYB0" title="The Easter Hymn sung by Eva-Maria Westbroek and The Royal Opera Chorus"/>

          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

  
          <div id="ezoic-pub-ad-placeholder-103"> </div>


            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Opera - Running Time: 80 mins</SynopsisAct>

            <p>A lot has happened before Cavallaria begins, which is fantastic as it keeps the opera itself compact. Turiddu and Lola were engaged to be married. However, Turiddu had to leave for military service, and while he was away, Lola married Alfio. Turiddu has now returned and found Lola married. He decided to seduce Santuzza, another girl in the village, provoking jealousy in Lola, resulting in an ongoing affair between Lola and Turiddu…</p>

            <ImageFull>
              <StaticImage src="../../images/operas/cavalleria/met_square_martysohl.jpg" alt="The Town Square in the Metropolitan Opera's Zeffirelli production"/>
              <figcaption>The Town Square in the Metropolitan Opera's Zeffirelli production &copy; Marty Sohl/Metropolitan Opera</figcaption>
            </ImageFull>

            <p>The curtain rises on the town square of a Sicilian village on Easter morning. Turiddu is heard offstage singing a love song to Lola. The villagers enter and sing a lovely choral number as they prepare for Easter.</p>

            <p>Santuzza arrives. She is distraught as she thinks (accurately) that Turiddu has betrayed her (plus some good ol' Catholic guilt about sex before marriage). She approaches Lucia, Turiddu's mother, asking where Turiddu is, and Lucia tells her he has left town to fetch some wine (Lucia is a wine merchant). Santuzza is surprised as she's heard he's been seen overnight in the village.</p>

            <p>Alfio arrives and asks Lucia for some wine. Lucia tells him she has none, but Turiddu has gone to fetch more. Alfio replies that he's back as he saw Turiddu that morning near his cottage. The village continues to gather and kicks off the Easter hymn, one of the most magnificent parts of the opera.</p>

            <p>Everyone enters the church except for Santuzza and Lucia. Santuzza tells Lucia everything. Lucia treats her kindly, but Santuzza feels dishonoured and says she cannot enter the church as she has slept with a man outside marriage. She begs Lucia to pray for her.</p>

            <VideoPlayer src="https://www.youtube.com/embed/hs4BlQgkELc?autoplay=1" id="hs4BlQgkELc" title="Elīna Garanča singing the aria 'Voi lo sapete, o mamma' (in what must be said is an unusual video!)"/>

            <p>Turiddu arrives, and Santuzza confronts him. Lola then arrives happily singing; she mocks Santuzza and goes into the church. Turiddu follows her, Santuzza begging him to stay, but he physically tosses her aside and enters the church after Lola. Alfio arrives looking for Lola. Santuzza cannot stop herself and tells him everything. Alfio swears to take revenge! Santuzza panics (as she still loves Turiddu) and begs Alfio to stop, but his mind is made up.</p>

            <p>The square is left empty, and the orchestra plays the remarkable Intermezzo.</p>

            <VideoPlayer src="https://www.youtube.com/embed/BIQ2D6AIys8?autoplay=1" id="BIQ2D6AIys8" title="The City of Prague Philharmonic Orchestra plays the Intermezzo"/>

            <p>The service is over, and everyone comes out of the church. Turiddu feels high; he is with Lola, and Santuzza has left. He invites everyone over for a drink, singing a happy drinking song. Alfio joins them, and when Turiddu offers him a drink, he refuses it, and things get spicey fast. Alfio challenges Turiddu to a duel which Turiddu accepts.</p>

            <p>Alfio leaves for the orchard, where the duel will take place. In one of the opera's great moments, Turiddu talks with his mother and requests that if he does not return, she acts as a mother to Santuzza.</p>

            <VideoPlayer src="https://www.youtube.com/embed/028egtobEYg?start=10&autoplay=1" id="028egtobEYg" title="Roberto Alagna sings 'Mamma, quel vino è generoso' at the Royal Opera"/>

            <p>Lucia waits alone, Santuzza arrives, and they embrace. There are cries in the distance. Turiddu is dead!</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Where and When"/>

            <p><em>Cavalleria</em> is set in a Sicilian village on Easter Morning, roughly contemporaneously to its first performance, so around the late 1800s. In practice, the location is both precise and quite general. The author of the play Giovanni Verga depicts the world he grew up in, the poor, hardworking and religiously devout Sicily. In practice, however, Mascagni (very much from the wealthy north of Italy) wrote an opera that could be set almost anywhere in Southern Italy. The narrative is universal, and you’ll see productions set the story practically anywhere.</p>

            <Header title="History"/>

            <p>When <em>Cavalleria rusticana</em> premiered on May 17th, 1890, it turned Mascagni into a superstar virtually overnight. However, this was a classic case of sudden success coming after years of toil.</p>

            <p>Mascagni was born in 1863, the son of a baker. He soon showed immense musical aptitude, writing multiple whole pieces before age 18. These pieces brought him the attention of Amilcare Ponchielli, one of Italy’s great composers of the era, who encouraged him to attend the Milan Conservatory. Mascagni’s father would prefer he become a lawyer, but he persevered and enrolled in 1882. His roommate there would be no less than a young Giacomo Puccini!</p>

            <p>After three years of study, things fell apart for Mascagni, and he was asked to leave the conservatory, never receiving his diploma. There began a rough period in Mascagni’s life where he earnt a meagre living conducting for a series of small opera companies around Italy’s provinces. He continued to compose when he could, working at length on Guglielmo Ratcliff, an opera he hammered at for over a decade.</p>

            <ImageRight maxwidth="240px">
              <StaticImage src="../../images/operas/cavalleria/Portrait_of_Giovanni_Verga.jpg" alt="Portrait of Giovanni Verga"/>
              <figcaption>Portrait of Giovanni Verga</figcaption>
            </ImageRight>

            <p>His fortunes changed when in 1888, the music publisher Sonzogno announced a competition for new one-act operas. Mascagni had eleven months to put an opera together, and working with a friend, Giovanni Targioni-Tozzetti, decided to adapt the successful play <em>Cavalleria rusticana</em> by Giovanni Verga, based on his own short story. The resulting opera won the competition, premiered at the Teatro Costanzi in Rome and launched Mascagni’s career.</p>

            <p>Mascagni wrote many more operas, but none came close to matching the popular success of <em>Cavalleria</em>. His follow-up the next year: L’amico Fritz was a huge pivot, a rather calmer, pastoral opera. Otherwise, the 1898 Iris is still occasionally performed today (despite some dodgy orientalism and a brutally sad plot). It shows a composer far more willing to experiment than he gets credit for. He eventually got Guglielmo Ratcliff performed in 1895 but it never really took off, not helped by being extremely difficult to sing for the title role tenor.</p>

            <p>Mascagni’s legacy is not helped by his rather bitter later years and his overt support for Mussolini and Fascism. He died in 1945 at the age of 81, having written 15 operas, but he will forever be known for <em>Cavalleria</em>.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Verismo Opera"/>

            <p>Verismo as an operatic category is easy to define but a bit woolly around the edges. They are operas that focus on ordinary (usually 'poor') people in common situations. That sounds pretty unremarkable now, but it bucked a historical trend of dramatic opera primarily concerning the very rich or the very mythological. That simple definition captures all sorts of operas however, not least a few works of Verdi, such as <em>Luisa Miller</em> and then <Link to="/operas/traviata">La Traviata</Link>, and most significantly Bizet's <Link to="/operas/carmen">Carmen</Link>, which definitely would count were it not French. So we can add Italian as a requirement and that the music follows the text in a more realistic, through-sung style (something Verdi edged towards throughout his career).</p>

            <p>The premiere of <em>Cavalleria</em> offers the moment that we consider the Verismo movement coming into being. However, Mascagni didn't pull this genre from thin air. He was expanding an existing literary movement into a musical one. Internationally literary naturalism was all the rage, with realistic depictions of society pushing back on the Romanticism that had dominated much of the 19th Century (think the grand works of Wagner). Mascagni married many of the Romantic musical developments, integrated music lacking the earlier more formal structures and set pieces, but ditched their high flung narratives for something altogether more earthy.</p>

            <p>The movement had a major presence throughout the 1890s. <em>Pagliacci</em> came soon after, Leoncavallo directly inspired by the popularity of <em>Cavalleria</em>. Verismo continued into the early 20th Century before petering out in the 1920s. Today, the most prominent of the 'Veristi' is Giacomo Puccini, whose <Link to="/operas/tosca">Tosca</Link>, Fanciulla del West and Il Tabarro are firmly in the Verismo camp. Of the dozens of Verismo works written in this period, not that many have had much staying power. Of the also-rans, Umberto Giordano's <em>Andrea Chénier</em> and Francesco Cilea's <em>Adriana Lecouvreur</em> still get fairly regular outings around the globe.</p>

            <Header title="Fun Facts"/>

            <FunFactsHeader>Popularity</FunFactsHeader>

            <p>From the moment of its sensational debut, <em>Cavalleria</em> has been a wildly popular opera. By the end of 1891, it had been performed across Europe from Moscow to London and as far afield as New York and Buenos Aires. By 1945, when Mascagni's died, the opera had been performed over fourteen thousand times in Italy. It has never really left the operatic stages and is still among the top 50 most performed operas worldwide. The Metropolitan Opera in New York has performed it over seven hundred times, including, rather intriguingly, over one hundred times paired with Hansel and Gretel (they haven't done this since 1938!).</p>

            <FunFactsHeader>Movie Scores</FunFactsHeader>

            <p><em>Cavalleria</em> has featured prominently in a couple of 20th Century movies. Most significantly, it provides the backdrop to the finale of The Godfather: Part III, with one of the characters performing the role of Turiddu in an in-movie production of <em>Cavalleria</em>.</p>

            <p>The Intermezzo was also used by Martin Scorcese for the opening sequence of his 1980 film Raging Bull. It's a magnificently simple sequence wonderfully enhanced by the score.</p>

            <VideoPlayer src="https://www.youtube.com/embed/wQhwi8kk-dE?start=10&autoplay=1" id="wQhwi8kk-dE" title="Raging Bull's opening sequence"/>

            <FunFactsHeader>Mascagni and Facism</FunFactsHeader>

            <ImageRight>
              <StaticImage src="../../images/operas/cavalleria/MasWithMussoliniSm.jpg" alt="Mussolini and Mascagni"/>
              <figcaption>Mussolini and Mascagni in 1927</figcaption>
            </ImageRight>

            <p>Long after the premiere of <em>Cavalleria</em> and with no further hits, Mascagni found himself in the 1920s rather shorter on funds than he would have liked (though scarcely poor). Perhaps more for personal advantage than personal politics, he successfully ingratiated himself into Mussolini's circle. After Puccini's death, he was now Italy's preeminent living composer, and he sought to take advantage of that status. He used this association with the Fascist Party to fill his coffers, receiving nearly 1.3 million lire from the propaganda ministry and was in turn, used by the Party to compose and conduct music for events. After the fall of Mussolini, Mascagni found himself in a less than ideal situation. However, he somehow weaselled himself into the French liberators' good books and was allowed to remain in his home, The Hotel Plaza, until his death not long after in 1945.</p>
          
          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>            

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

            <Header title="The Original Story"/>   
            
            <p>D. H. Lawrence was a huge fan of Giovanni Verga and translated a great many of his works. In 1928 he translated <em>Cavalleria rusticana</em> and as it is now out of copyright you can find it in its entirity below!</p>         

            <h5><em>Cavalleria rusticana</em> by Giovanni Verga, Translated by D. H. Lawrence</h5>

            <p>Turiddu Macca, son of old Mother Nunzia, when he came home from being a soldier, went swaggering about the village square every Sunday, showing himself off in his bersagliere’s uniform with the red fez cap, till you’d have thought it was the fortune-teller himself come to set up his stall with the cage of canaries. The girls going to Mass with their noses meekly inside their kerchiefs stole such looks at him, and the youngsters buzzed round him like flies. And he’d brought home a pipe with the king on horseback on the bowl, simply life-like, and when he struck a match on his trousers behind, he lifted his leg up as if he was going to give you a kick.</p>

            <p>But for all that, Lola, Farmer Angelo’s daughter, never showed a sign of herself, neither at Mass nor on her balcony; for the simple reason that she’d gone and got herself engaged to a fellow from Licodia, a carter who took contracts, and had four handsome Sortino mules of his own in his stable.</p>

            <p>When Turiddu first got to hear of it, oh, the devil! he raved and swore!—he’d rip his guts out for him, he’d rip ’em out for him, that Licodia fellow!—But he never did a thing, except go and sing every slighting song he could think of under the beauty’s window.</p>

            <p>“Has Mother Nunzia’s Turiddu got nothing else to do but sing songs like a forlorn sparrow, every mortal night?” said the neighbours.</p>

            <p>However, he ran into Lola at last, as she was coming back from her little pilgrimage to Our Lady of Peril; and she, when she saw him, never turned a hair, as if it was nothing to do with her.</p>

            <p>“It’s rare to set eyes on you!” he said to her.</p>

              <div id="ezoic-pub-ad-placeholder-107"> </div>
  
            <p>“Hello, Turiddu! They told me you’d come back on the first of this month.”</p>

            <p>“They told me more than that!” he replied.”Is it right as you’re marrying Alfio, as contracts for carting?”</p>

            <p>“God willing, I am,” replied Lola, twisting the corners of her kerchief at her chin.</p>

            <p>“There’s a lot o’ God willing about it! You suit your own fancy! And it was God willing as I should come home from as far as I did, to hear this nice bit of news, was it, Lola?”</p>

            <p>The poor man tried to keep a good face, but his voice had gone husky; and he walked on at the heels of the girl, the tassel of his fez cap swinging melancholy to and fro, on his shoulders. And to tell the truth, she was sorry to see him with such a long face; but she hadn’t the heart to cheer him up with false promises.</p>

            <p>“Look here, Turiddu,” she said at last to him, “let me go on and join the others. What do you think folks’ll say if they see me with you?”</p>

            <p>“You’re right!” replied Turiddu.”Now you’re going to marry that chap Alfio, as has got four mules of his own in his stable, it’d never do to set folks talking! Not like my poor old mother, as had to sell our bay mule and the bit of a vineyard, while I was away soldiering. —Ah well, the time’s gone by when Bertha sat a-spinning!—And you’ve forgotten how we used to talk together at the window in the yard, and how you gave me that handkerchief before I went away—God knows how many tears I cried in it, going that far off, I’d almost forgotten even the name of where I came from. —Well, good-bye, then, Lola. It showered a while, and then left off, and all was over between us!”</p>

            <p>And so Miss Lola married the carter; and the Sunday after, there she sat on her balcony, with her hands spread on her stomach to show all the great gold rings her husband had given her. Turiddu kept going back and forth, back and forth up the narrow street, his pipe in his mouth and his hands in his pockets, to show he didn’t care, and ogling all the girls. But it gnawed him inside himself to think that Lola’s husband should have all that gold, and that she pretended not to notice him, when he passed.</p>

            <p>“I’ll show that bitch summat, afore I’ve done!” he muttered to himself.</p>

            <p>Across from Alfio’s house lived Farmer Cola, the wine-grower, who was as rich as a pig, so they said, and who had a daughter on his hands. Turiddu so managed it that he got Farmer Cola to take him on, helping in the vines, and then he started hanging round the house, saying nice things to the girl.</p>

            <p>“Why don’t you go and say all those sweet nothings to Mrs Lola, over the road?” Santa replied to him.</p>

              <div id="ezoic-pub-ad-placeholder-108"> </div>
  
            <p>“Mrs Lola thinks she’s somebody. Mrs Lola’s married my Lord Tomnoddy, she has!”</p>

            <p>“And I’m not good enough for a Lord Tom-noddy, am I?”</p>

            <p>“You’re worth twenty Lolas. And I know somebody as wouldn’t look at Mrs Lola, nor at the saint she’s named after, if you was by. Mrs Lola’s not fit to bring you your shoes, she’s not.”</p>

            <p>“Ah là! it’s sour grapes, as the fox said when he couldn’t reach———”</p>

            <p>“No, he didn’t! He said: ‘Ah, but you’re sweet, my little gooseberry!’ “</p>

            <p>“Eh! Keep your hands to yourself, Turiddu!”</p>

            <p>“Are you afraid I shall eat you?”</p>

            <p>“I’m neither afraid of you nor your Maker.”</p>

            <p>“Eh! your mother was a Licodia woman, we know it! You’ve got a temper right enough. Oh! I could eat you with my eyes!”</p>

            <p>“Eat me with your eyes, then; we shall make no crumbs! But while you’re at it, lift me that bundle of kindling.”</p>

            <p>“I’d lift the whole house up for you, that I would.”</p>

            <p>She tried to hide her blushes, threw a stick at him which she’d got in her hand, and for a wonder missed him.</p>

            <p>“Let’s look sharp! We shall bind no kindling with nothing but talk.”</p>

                <div id="ezoic-pub-ad-placeholder-109"> </div>
    
            <p>“If I was rich, I should look for a wife like you, Miss Santa.”</p>

            <p>“Eh well! I shan’t marry my Lord Tom-noddy, like Mrs Lola, but I shan’t come empty-handed neither, when the Lord sends me the right man.”</p>

            <p>“Oh ay! we know you’re rich enough, we know that.”</p>

            <p>“If you know it, then hurry up; my Dad’ll be here directly, and I don’t want him to catch me in the yard.”</p>

            <p>Her father began by making a wry face, but the girl pretended not to notice. The tassel of the bersagliere’s cap had touched her heart, swinging in front of her eyes all the time. When her father put Turiddu out of the door, she opened the window to him, and stood there chattering to him all the evening, till the whole neighbourhood was talking about nothing else.</p>

            <p>“I’m crazy about you,” Turiddu said.”I can neither eat nor sleep.”</p>

            <p>“You say so———”</p>

            <p>“I wish I was Victor Emmanuel’s son, so I could marry you.”</p>

            <p>“You say so———”</p>

            <p>“Oh, Madonna, I could eat you like bread!”</p>

            <p>“You say so———”</p>

            <p>“Ah, I tell you it’s true!”</p>

            <p>“Eh, mother, mother!”</p>

            <p>Night after night Lola listened, hidden behind a pot of sweet basil in her window, and going hot and cold by turns. One day she called to him:</p>

            <p>“So that’s how it is, Turiddu? Old friends don’t speak to one another any more!”</p>

            <p>“Why!” sighed the youth.”It’s a lucky chap as can get a word with you.”</p>

            <p>“If you want to speak to me, you know where I live,” replied Lola.</p>

            <p>Turiddu went so often to speak to her, that Santa was bound to notice it, and she slammed the window in his face. The neighbours nodded to one another, with a smile, when the bersagliere went by. Lola’s husband was away, going round from fair to fair, with his mules.</p>

            <p>“I mean to go to confession on Sunday. I dreamed of black grapes last night,” said Lola.</p>

            <p>“Oh, not yet, not yet!” Turiddu pleaded.</p>

            <p>“Yes. Now it’s getting near Easter, my husband will want to know why I’ve not been to confession.”</p>

            <p>“Ah!” murmured Farmer Cola’s Santa, waiting on her knees for her turn in front of the confessional, where Lola was having a great washing of her sins: “It’s not Rome I’d send you to for a penance, it isn’t, my word it isn’t!”</p>

            <p>Master Alfio came home with his mules, and a good load of cash, and brought a fine new dress as a present to his wife, for the festival.</p>

            <p>“You do well to bring her presents,” his neighbour Santa said to him.”She’s been adorning your house for you, while you’ve been away.”</p>

            <p>Master Alfio was one of those carters who go swaggering beside their horse with their cap over their ear; so when he heard his wife spoken of in that way, he went white as if he’d been stabbed.</p>

            <p>“By God, though!” he exclaimed.”If you’ve seen more than there was to see, I won’t leave you your eyes to cry with, neither you nor the rest of your folks.”</p>

            <p>“I’m not the crying sort,” replied Santa.”I didn’t cry even when I saw with my own eyes Mother Nunzia’s Turiddu creeping into your wife’s house at night.”</p>

            <p>“All right!” replied Alfio.”I’m much obliged!”</p>

                <div id="ezoic-pub-ad-placeholder-110"> </div>
    
            <p>Now that the cat had come back, Turiddu no longer hung round the little street in the daytime, but whiled away his chagrin at the inn, with his friends; and on the Saturday evening before Easter they had a dish of sausages on the table. When Master Alfio came in, Turiddu knew in an instant, from the way he fixed his eyes on him, what he’d come for, and he put his fork down on his plate.</p>

            <p>“Did you want me for anything, Alfio?” he said.</p>

            <p>“Nothing particular, Turiddu. It’s quite a while since I’ve seen you, and I thought I’d have a word with you—you know what about.”</p>

            <p>At first Turiddu had offered him his glass, but he put it aside with his hand. Then Turiddu rose, and said;</p>

            <p>“Right you are, Alfio!”</p>

            <p>The carter threw his arms round his neck.</p>

            <p>“Shall you come to the cactus grove at Canziria to-morrow morning, and we can talk about that bit of business of ours, boy?”</p>

            <p>“Wait for me on the high-road at sunrise, and we’ll go together.”</p>

            <p>With these words, they exchanged the kiss of challenge; and Turiddu nipped the carter’s ear between his teeth, thus promising solemnly not to fail him.</p>

            <p>His friends had all quietly abandoned the sausages, and they walked with Turiddu home. Mother Nunzia, poor thing, sat up waiting for him till late every evening.</p>

            <p>“Mother,” Turiddu said to her, “you remember when I went for a soldier, you thought I should never come back? Now kiss me like you did then, because I’m going off in the morning, a long way.”</p>

            <p>Before daybreak he took his clasp-knife, which he had hidden under the hay when he was taken off as a conscript to the army, and then he set out for the cactus grove at Canziria.</p>

            <p>“Oh Jesu-Maria! where are you going in such a fury?” whimpered Lola in dismay, as her husband was getting ready to go out.</p>

            <p>“I’m not going far,” replied Master Alfio.”And better for you if I never come back.”</p>

            <p>Lola, in her night-dress, kneeled praying at the foot of the bed, pressing to her lips the rosary which Fra Bernardino had brought from the Holy Land, and repeating all the Ave Marias there were to repeat.</p>

            <p>“You see, Alfio,” Turiddu began, after he had walked for some distance along the road beside his silent companion, who had his cap pulled down over his eyes, “as true as God’s above, I know I’m in the wrong, and I would let myself be killed. But my old mother got up before I started out, pretending she had to see to the fowls, and I could tell she knew. So as sure as God’s above, I’m going to kill you like a dog, so the poor old woman shan’t have to cry her eyes out.”</p>

            <p>“All right, then,” replied Alfio, pulling off his sleeved waistcoat.”Now we shall strike hard, both of us.”</p>

            <p>They were both good fighters with the knife. Alfio struck the first thrust, and Turiddu was quick enough to catch it on his arm. When he gave it back, he gave a good one, aiming at the groin.</p>

            <p>“Ah! Turiddu. Do you really mean to kill me?”</p>

            <p>“Yes, I told you! Since I saw my old woman with the fowls, I can’t get her out of my eyes.”</p>

            <p>“Then open your eyes, then!” Alfio shouted at him; “I’ll give you more than you asked for.”</p>

            <p>And as the carter stood on guard, doubled up so as to keep his left hand over his wound, which hurt him, his elbow almost brushing the ground, suddenly he seized a handful of dust and threw it full in his enemy’s eyes.</p>

            <p>“Ah!” screamed Turiddu, blinded.”I’m done!”</p>

            <p>He tried to save himself by jumping desperately backwards, but Alfio caught him up with another stab in the stomach, and a third in the throat.</p>

            <p>“——and three! That’s for the house which you adorned for me! And now your mother can mind her fowls——”</p>

            <p>Turiddu reeled about for a moment or two here and there among the cactuses, then fell like a stone. The blood gurgled frothing from his throat, he couldn’t even gasp: “Oh, Mother!”</p>


        </MainBodySection>
    </Layout>
  )
}

export default CavalleriaPage
